
import TenantContent from '@/components/elements/TenantContent'
import LoginForm from '@/components/forms/LoginForm.vue'
import { indexItemsById } from '@/helpers/IndexHelpers'
import { changeMemberDetailsInstructions } from '@/language/helpers'
import { memberHasNoEmailAddress } from '@/helpers/RequireMemberEmailAddress'
import { isLoggedIn } from '@/state/Authentication'
import { isCurrentMember, membershipIsRenewable, membershipRenewableFrom } from '@/state/Membership'
import store from '@/store/store'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { LanguageStrings } from '@/language/types'
import { TixTime } from '@/TixTime/TixTime'
import type { TTStringFormat } from '@/TixTime/helpers'

@Component({
  name: 'MembershipModal',
  components: { LoginForm, TenantContent },
  computed: mapGetters({
    user: 'Member/user',
    membership: 'Member/membership',
    membershipLevelName: 'Member/membershipLevelName',
    membershipId: 'Member/membershipId',
  }),
})
export default class extends Vue {
  t: LanguageStrings['membershipModal']

  membershipId: string
  membershipLevelName: string
  membership: undefined | { auto_renew_state: AutoRenewState; ticket_order_id: string }

  get hasMembership(): boolean {
    return isCurrentMember()
  }

  get expiry(): string {
    const expiry: TixTime = store.getters['Member/membershipExpiry']
    const medium = this.$t('dateFormat.medium') as TTStringFormat
    const formattedExpiry = expiry.format(medium)
    const renewFrom = membershipRenewableFrom()?.format(medium)

    if (!this.renewAllowed && renewFrom) {
      return `${formattedExpiry} (renew from ${renewFrom})`
    } else {
      return formattedExpiry
    }
  }

  get renewAllowed() {
    return membershipIsRenewable()
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }

  get primaryMember() {
    return this.members[0]
  }

  get secondaryMember() {
    return this.members[1]
  }

  get otherMemberNames(): string {
    return this.members
      .filter((member, index) => index > 1)
      .map((member) => member.admitName)
      .join(', ')
  }

  get emailAddress(): string {
    const user = store.getters['Member/user']
    return memberHasNoEmailAddress() ? this.t.noEmailMessage : user.email
  }

  get changeMemberDetailsInstructions(): string {
    return changeMemberDetailsInstructions()
  }

  renewClicked() {
    this.$modals.close()
    this.$router.push('/membership/renew')
  }

  get canCancelAutoRenew(): boolean {
    return this.membership?.auto_renew_state === 'scheduled'
  }

  cancelAutoRenew() {
    const id = this.membership?.ticket_order_id
    this.$router.push(`/manage/${id}/cancel-auto-renew`)
    this.$modals.close()
  }

  private get members(): { admitName: string; typeName: string }[] {
    const types: Dict<TicketType> = indexItemsById(store.getters['Member/ticketTypes'])
    return (store.getters['Member/tickets'] as Ticket[])
      .filter((ticket) => ticket.admit_name.length > 0)
      .map((ticket) => ({ ticket, type: types[ticket.ticket_type_id] }))
      .sort((a, b) => a.type._rank - b.type._rank)
      .map(({ ticket, type }) => ({
        admitName: ticket.admit_name,
        typeName: type.name,
      }))
  }
}
