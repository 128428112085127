
import { fetchFirstAvailableSession } from '@/api/Sessions'
import type { ReserveTicketPayload } from '@/api/types/payloads'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import RenewMembershipMessage from '@/components/elements/RenewMembershipMessage.vue'
import AdmitDetailsFields from '@/components/events/AdmitDetailsFields.vue'
import SelectQuantities from '@/components/events/SelectQuantities.vue'
import SelectSeats from '@/components/events/SelectSeats.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import Survey2 from '@/components/forms/Survey2.vue'
import { languageItem } from '@/language/helpers'
import { getButtonLabel, reserveTicketsPayload } from '@/helpers/Reserve'
import { getSessionTicketGroupsAndTypes } from '@/helpers/SessionHelpers'
import { surveySpecsWithTicketGroupId, SurveySpecWithTicketGroupID } from '@/helpers/SurveyHelpers'
import { isForCodes, isForFlexibleTickets } from '@/helpers/TicketGroupHelpers'
import { createQuantities, sumQuantities } from '@/helpers/TicketTypeQuantities'
import { formIsValid, reportFormValidity } from '@/helpers/Validation'
import { AssignedSeat, assignSeatsByTT } from '@/seats/assignments'
import { seatSelectionNeeded } from '@/seats/helpers'
import { reserveSubmitHandler, ReserveSubmitOptions } from '@/state/Reserve'
import type { TimedItem } from '@/store/CartItem'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MobileFooterPortal from '../cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '../cart/ReserveMobileFooter.vue'
import DynamicMessageGroup from '@/components/events/DynamicMessageGroup.vue'
import EventDetailErrorMessages from '@/components/events/EventDetailErrorMessages.vue'
import type { Session } from '@/types/Sessions'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { getPricedTicketGroups } from '@/helpers/PriceCheck'

@Component({
  name: 'ReserveSingleEvent',
  components: {
    SelectQuantities,
    SelectSeats,
    AdmitDetailsFields,
    NavigateBack,
    FormInput2,
    Survey2,
    MobileFooterPortal,
    ReserveMobileFooter,
    RenewMembershipMessage,
    DynamicMessageGroup,
    EventDetailErrorMessages,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  readonly formId = 'reserve-single-event-form'

  @Prop()
  contexts: Set<string>

  loading: boolean = true
  submitting: boolean = false
  apiError: any = null

  session: Session | null = null
  sessionTicketGroups: LinkedTG[] | null = null

  selectedQuantities: TicketTypeQuantities = {}
  selectedSeats: AssignedSeat[] | null = null

  surveyAnswers: Dict<Dict<Primitive>> = {}
  details: Dict<EventAdmitDetails[]> = {}

  formIsValid: boolean = false

  seatMapOpen: boolean = false

  discountCodePurchase: boolean = false

  l = languageItem('reserve')

  @Watch('event', { immediate: true })
  onEventChange(event: EventDetails) {
    this.loading = true
    if (this.event.ticketGroups.every(isForCodes)) {
      this.loading = false
      this.discountCodePurchase = true
      this.sessionTicketGroups = this.event.ticketGroups
      getPricedTicketGroups(this.event.id, this.sessionTicketGroups).then(
        (groups) => (this.sessionTicketGroups = groups),
      )
    } else {
      fetchFirstAvailableSession(event)
        .then((session) => {
          this.session = session
          this.sessionTicketGroups = null
          return this.getSelectedQuantities(event, session)
        })
        .then((quantities) => {
          this.selectedQuantities = quantities
          this.loading = false
        })
    }
  }

  getSelectedQuantities(event: EventDetails, session: Session | null): Promise<TicketTypeQuantities> {
    if (session) {
      const tgIDs = event.ticketGroups.map((group) => group.id)
      return getSessionTicketGroupsAndTypes(this.event.id, session.id, tgIDs).then((groups) => {
        this.sessionTicketGroups = groups
        return createQuantities(groups, this.session, this.anchor)
      })
    } else {
      return Promise.resolve({})
    }
  }

  get showQuantitySelection(): boolean {
    return !this.seatMapOpen
  }

  get fewTicketsAvailable(): boolean {
    // TODO 💦 Should this be reading available capacity from the ticket group?
    return this.session ? this.session.availableCapacity < 10 : false
  }

  get anchor(): TimedItem {
    return this.$store.getters['Cart/anchor']
  }

  // TODO: Deduplicate from <ReserveDateFirst>
  get submitButtonEnabled(): boolean {
    if (this.seatSelectionNeeded || this.submitting || this.totalSelectedTickets < 1) {
      return false
    } else {
      // TODO Document why submit button should always be enabled when purchasing discount codes.
      return this.discountCodePurchase || this.session != null
    }
  }

  get seatSelectionNeeded() {
    return seatSelectionNeeded(
      this.showSeatSelection,
      this.selectedSeats,
      this.selectedSeatedGroups,
      this.selectedQuantities,
    )
  }

  get selectedSeatedGroups(): LinkedTG[] | undefined {
    return this.selectedGroups?.filter((group) => group.handler === 'seated')
  }

  get selectedGroups(): LinkedTG[] | undefined {
    return this.ticketGroups?.filter((group) => {
      return group.types.some((type) => this.selectedQuantities[type.id]?.quantity > 0)
    })
  }

  get surveySpecs(): SurveySpecWithTicketGroupID[] {
    return surveySpecsWithTicketGroupId(this.selectedGroups)
  }

  private get reservePayload(): ReserveTicketPayload[] {
    const result: ReserveTicketPayload[] = []

    const flexibleTTs = this.flexibleTicketTypeIDs

    const seats = assignSeatsByTT(this.selectedSeats, this.selectedQuantities, this.selectedSeatedGroups!)
    for (const [id, { quantity, price }] of Object.entries(this.selectedQuantities)) {
      const quantities = { [id]: { quantity, price } }
      // Omit the session ID for ticket types belonging to flexible-ticket ticket groups.
      const session = flexibleTTs.has(id) ? undefined : this.session
      result.push(...reserveTicketsPayload(quantities, session, this.details, seats))
    }

    return result
  }

  private get flexibleTicketTypeIDs() {
    const result = new Set<string>()

    for (const group of this.ticketGroups) {
      if (isForFlexibleTickets(group)) {
        for (const ticketType of group.types) {
          result.add(ticketType.id)
        }
      }
    }

    return result
  }

  @Watch('details', { deep: true })
  @Watch('totalSelectedTickets')
  @Watch('surveyAnswers', { deep: true })
  updateValidation() {
    // This is called in the render phase, before refs have been linked.
    // If the session and quantity are both set on initialisation then this.$refs.form is still not defined.
    this.formIsValid =
      this.totalSelectedTickets > 0 && this.$refs.form && formIsValid(this.$refs.form as HTMLFormElement)
  }

  onSubmit() {
    this.submit()
  }

  submit(codes?: string[]): Promise<void> {
    const submitOptions: ReserveSubmitOptions = {
      ticketsPayload: this.reservePayload,
      surveyAnswers: this.surveyAnswers,
      selectedGroups: this.selectedGroups ?? [],
      emitCallback: this.$emit.bind(this),
      codes: codes,
    }

    return this.validate()
      .then(() => {
        this.submitting = true
      })
      .then(() => reserveSubmitHandler(submitOptions))
      .catch((error) => {
        this.apiError = error
      })
      .finally(() => {
        this.submitting = false
      })
  }

  validate() {
    return reportFormValidity(this.$refs.form as HTMLFormElement)
  }

  get ticketGroups(): LinkedTG[] {
    return this.sessionTicketGroups ?? []
  }

  get totalSelectedTickets(): number {
    return sumQuantities(this.selectedQuantities)
  }

  get showSeatSelection(): boolean {
    return (this.selectedSeatedGroups && this.selectedSeatedGroups.length > 0) ?? false
  }

  get giftOfMembership() {
    return this.contexts.has('gift-of-membership')
  }

  get buttonLabel() {
    return getButtonLabel(this.discountCodePurchase, this.giftOfMembership, this.totalSelectedTickets)
  }
}
