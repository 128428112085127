
import MobileCartWidget from '@/components/cart/MobileCartWidget.vue'
import TheFooter from '@/components/Footer.vue'
import TheHeader from '@/components/Header.vue'
import ModalHandler from '@/modals/helpers/ModalHandler.vue'
import { apiErrorMessage, fallbackErrorMessage, logInTrackJS } from '@/errors/helpers'
import { environment } from '@/helpers/Environment'
import { closeCartModal } from '@/modals/cartModal'
import CartExpire from '@/plugins/CartExpire'
import { isLoggedIn } from '@/state/Authentication'
import store from '@/store/store'
import { getCookie } from 'tiny-cookie'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { registerModalEventListeners } from '@/modals/helpers/api'

@Component({
  name: 'App',
  mixins: [CartExpire],
  components: {
    ModalHandler,
    TheFooter,
    TheHeader,
    MobileCartWidget,
  },
})
export default class extends Vue {
  error: string | null = null

  created() {
    window.addEventListener('unhandledrejection', (error) => {
      const apiError = apiErrorMessage(error.reason, '') ?? null
      if (apiError) {
        this.error = apiError
        logInTrackJS(`Unhandled Tix API rejection: ${this.error}`, error.reason)
      } else {
        this.tellUserAboutUnexpectedError()
      }
      // Do not throw the error. That would cause it to be duplicated.
    })

    // Register the generic modals event listener.
    registerModalEventListeners()

    // Regularly check if the logged in user's session has expired by
    // looking at the login time and how long a session is valid for.
    TrackJS!.addMetadata('Authenticated on init', getCookie('session_expires') ? 'yes' : 'no')
    setInterval(() => {
      // WARNING: Errors here may cause the server to get hammered with continuous
      // queries from all browsers open on all devices.
      const hasSessionCookie = getCookie('session_expires')
      TrackJS!.addMetadata('Authenticated', hasSessionCookie ? 'yes' : 'no')

      if (isLoggedIn() && !hasSessionCookie) {
        TrackJS!.addMetadata('Was previously authenticated', 'yes')
        // Session cookie already disappeared. Force logout and redirect to homepage.
        // initializeStores() also handles this scenario on initialization and without redirecting.
        this.endSession()
      }
    }, 1000)

    window.dataLayer.push({
      // Required a data layer variable set up on GTM
      tenant_slug: environment.portal.slug,
    })
  }

  errorCaptured() {
    this.tellUserAboutUnexpectedError()
  }

  tellUserAboutUnexpectedError() {
    // Tell users that something unexpected went wrong.
    this.error = fallbackErrorMessage
  }

  clearState() {
    return store.dispatch('clearAll')
  }

  endSession() {
    this.$modals.close({ name: 'membership-modal' })

    // Closes the member menu if opened.
    const menu = document.querySelector('.member-menu')
    if (menu) {
      menu.removeAttribute('open')
    }

    this.clearState()
    this.$router.push('/')
  }

  reload() {
    window.location.reload()
  }

  get routeName() {
    const name = (this.$route.name ?? '').replace('/', '-')
    return `route-${name}`
  }

  get ticketCount(): number {
    return store.getters['Cart/ticketCount']
  }

  @Watch('ticketCount')
  handleTicketCountChange(value: number) {
    if (value === 0) {
      closeCartModal()
    }
  }
}
