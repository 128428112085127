import { API, ApiArgs } from '@/api/API'
import {
  apiEntities,
  linkTGRelations,
  mergeConfigIntoEvent,
  mergeConfigIntoEvents,
  toLinkedGroups,
} from '@/api/Helpers'
import type {
  DateTimeTriggerDetail,
  EventDetails,
  EventListItem,
  LinkedTG,
  MessageRuleDetail,
} from '@/api/types/processedEntities'
import NotFoundError from '@/errors/NotFoundError'
import { groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import { normalizeSessions } from '@/helpers/SessionHelpers'
import type { TGQueryParam } from '@/helpers/TicketGroupHelpers'
import { excludeExpiredPasses } from '@/helpers/TicketGroupHelpers'
import { TixTime } from '@/TixTime/TixTime'

type BaseEventEntities = 'event_template' | 'config' | 'meta' | 'venue'
type EventDetailEntities = BaseEventEntities | 'ticket_group' | 'ticket_type' | 'message_rule'
type EventListEntities = BaseEventEntities | 'event_session'

export interface FetchAvailableEventsOptions {
  categories?: string[]
  // TODO Use TixDate?
  date?: TixTime
  includeSessions?: boolean
  metadataKeys?: string
}

export function fetchAvailableEvents(options: FetchAvailableEventsOptions): Promise<EventListItem[]> {
  const embed = ['meta', 'config', 'venue', 'ticket_type', 'ticket_group']
  const query = {
    _withmemberevents: true,
    'hidden_type._in': 'public_browsable,public_member_only',
    'config.key._in': 'config.image',
    'category._in': options.categories,
    'meta.metakey._in': options.metadataKeys,
  }

  if (options.date) {
    if (options.includeSessions) {
      embed.push('event_session')
    }
    // Prefer event_session.start_datetime filters over _ondate. The API response includes;
    //   - All untimed events, such as memberships
    //   - Timed events that have a session in the specified range
    //   - Sessions that start in the specified range and finish the day after. E.g. 11pm to 1am.
    // TODO Support a configurable threshold for day's end. E.g. 3am instead of 12am. Meow Wolf wants this.
    query['event_session.start_datetime._gte'] = options.date.startOfDay().format('iso')
    query['event_session.start_datetime._lt'] = options.date.endOfDay().format('iso')
  }

  return API.getCached<EventListEntities>('events/available', { query, embed }).then(toEventListItems)
}

// TODO Still worth unit testing?
export function toEventListItems(response: ApiResponse<EventListEntities>): EventListItem[] {
  const r = apiEntities(response)
  const venues: Dict<Venue> = indexItemsById(r.venue)
  const result: EventListItem[] = mergeConfigIntoEvents(r.event_template, response).map((event) => {
    return {
      ...event,
      venue: venues[event.venue_id],
    }
  })

  if (!r.event_session) {
    // Omit the sessions property completely from the results.
    return result
  } else {
    const sessions = groupBy('event_template_id', r.event_session)
    return result.map((event) => {
      return {
        ...event,
        sessions: normalizeSessions(sessions[event.id] ?? [], event.venue),
      }
    })
  }
}

/**
 * API sorts TGs and TTs by rank.
 *
 * @see https://tixtrackteam.slack.com/archives/C02E6JSGNHM/p1708575494601889
 */
export function fetchEventDetails(id: string, tgIDs?: TGQueryParam): Promise<EventDetails> {
  const tgVisibilityTypes = ['public_browsable', 'public_member_only']

  if (tgIDs) {
    // Include link-only ticket groups.
    tgVisibilityTypes.push('public_link_only')
  }

  const args: ApiArgs = {
    query: {
      'hidden_type._in': 'public_browsable,public_link_only,public_member_only',
      'ticket_group.id._in': tgIDs,
      'ticket_group.hidden_type._in': tgVisibilityTypes,
    },
    embed: 'meta, venue, ticket_group, ticket_group.meta, ticket_group.config, ticket_type, message_rule, config',
  }

  return API.getCached<EventDetailEntities>(`events/${id}`, args)
    .catch((error) => {
      throw handleError(error, id, tgIDs)
    })
    .then(toEventDetails)
}

export function fetchDonationTG(tgid: string): Promise<LinkedTG> {
  const params: ApiArgs = {
    embed: 'ticket_type',
    query: { handler: 'donation' },
  }

  return API.getCached<'ticket_group' | 'ticket_type'>(`ticket_group/${tgid}`, params).then((r) => {
    const result = linkTGRelations(r.ticket_group._data, [], r.ticket_type._data)
    return result[0]
  })
}

export function toMessageRuleDetails(rules: MessageRule[], timezone: string): MessageRuleDetail[] {
  return rules
    .map((rule) => ({
      ...rule,
      triggers: {
        ...rule.triggers,
        dateOrSession: rule.triggers.date_or_session?.map((trigger) => {
          const result: DateTimeTriggerDetail = {
            ...trigger,
          }

          if (trigger.date_range) {
            result.dateInterval = {
              start: new TixTime(trigger.date_range.start, timezone),
              // Add 24 hours so that it is the end of that day.
              end: new TixTime(trigger.date_range.end, timezone).add(24, 'hours'),
            }
          }

          return result
        }),
      },
    }))
    .sort((a, b) => a._rank - b._rank)
}

function toEventDetails(response: ApiResponse<EventDetailEntities>): EventDetails {
  const entities = apiEntities(response)
  const venue = entities.venue[0]
  return {
    ...mergeConfigIntoEvent(entities.event_template[0], response),
    venue,
    ticketTypes: entities.ticket_type,
    ticketGroups: toLinkedGroups(response).filter(excludeExpiredPasses),
    messageRules: entities.message_rule ? toMessageRuleDetails(entities.message_rule, venue.timezone) : [],
  }
}

function handleError(error, ...args) {
  const status = error?.response?.status
  if (status === 404 || status === 400) {
    return new NotFoundError('event_template', ...args)
  } else {
    return error
  }
}
