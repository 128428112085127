import StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import { environment } from '@/helpers/Environment'
import { isLoggedIn } from '@/state/Authentication'
import { cartExpired, refreshStoredCart } from '@/state/Cart'
import store from '@/store/store'
import { loadStripe } from '@stripe/stripe-js'
import { getCookie } from 'tiny-cookie'

export function initializeStores() {
  const hasSessionCookie = getCookie('session_expires')
  if (isLoggedIn() && !hasSessionCookie) {
    // The session has expired. Clear all storage, but stay on the current URL.
    store.dispatch('clearAll')
  } else {
    initializeCartStore()
    initializeMemberStore()
  }
}

/**
 * Removes any known carts that have expired, are empty, or not pending (purchased/deleted elsewhere).
 * Seeds a Stripe wallet payment to check if digital wallets are supported before getting to checkout.
 */
export function initializeCartStore(): void {
  if (cartExpired()) {
    store.dispatch('Cart/clearAll')
  } else if (store.getters['Cart/cart']) {
    // Reload the cart, which also confirms it is still valid.
    refreshStoredCart()
  }
}

/**
 * Initializes the members store in case of external authentication (MoS Boston).
 */
export function initializeMemberStore() {
  const hasSessionCookie = getCookie('session_expires')
  const identity = store.getters['Member/identity']

  if (hasSessionCookie && !identity) {
    store.dispatch('Member/load')
  }
}
