
import { API } from '@/api/API'
import ApplyPromoCodesCheckout from '@/checkout/components/ApplyPromoCodesCheckout.vue'
import BlockedCartForm from '@/checkout/components/BlockedCartForm.vue'
import TermsCheckbox from '@/checkout/components/TermsCheckbox.vue'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import { identityDataForStripe } from '@/checkout/helpers/identity'
import { getPaymentPayloads } from '@/checkout/helpers/payloads'
import {
  CheckoutOptions,
  CheckoutResult,
  CompletedCheckoutResult,
  PaymentTokens,
  PendingCheckoutResult,
  processPaymentPayloads,
} from '@/checkout/helpers/processing'
import { isWalletPayment, walletNames, WalletType } from '@/checkout/helpers/wallets'
import { authorizePayment, PaymentMethodName } from '@/checkout/stripe/helpers'
import StripeWalletPayment from '@/checkout/stripe/StripeWalletPayment'
import FloatingNavigateBack from '@/components/elements/FloatingNavigateBack.vue'
import MembersBanner from '@/components/elements/MembersBanner.vue'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import Price from '@/components/elements/Price.vue'
import CheckoutDonation from '@/components/events/CheckoutDonation.vue'
import IdentityForm from '@/components/forms/IdentityForm.vue'
import {
  apiErrorMessageOrRethrow,
  fallbackErrorMessage,
  getApiErrorEntity,
  logInTrackJS,
  notFoundRoute,
} from '@/errors/helpers'
import ValidationError from '@/errors/ValidationError'
import { getRedirectPaymentData, handlePaymentRedirectResponse } from '@/helpers/PaymentRedirectHelpers'
import { configYml, environment, portal } from '@/helpers/Environment'
import { getIdentityFormFields } from '@/helpers/IdentityFormHelpers'
import { configIdentityFields, IdentityFormData, upsertGuestAndGetId } from '@/helpers/IdentityHelpers'
import { changeMemberDetailsInstructions } from '@/language/helpers'
import { getPostCodeFilterRegularExpression } from '@/helpers/PostCodeAsDiscount'
import { applyPromoCode } from '@/helpers/PromoCodes'
import { memberHasNoEmailAddress } from '@/helpers/RequireMemberEmailAddress'
import { escapeHTML } from '@/helpers/StringHelpers'
import { reportFormValidity } from '@/helpers/Validation'
import type { LanguageStrings } from '@/language/types'
import { clearTimer } from '@/plugins/CartExpire'
import { injectCart, refreshStoredCart } from '@/state/Cart'
import type { Cart } from '@/store/Cart'
import type { ComponentOptions } from '@/types/ComponentOptions'
import type { PaymentMethod, Stripe, StripeElements } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import {
  getCartPayload,
  getPendingPurchasePayloads,
  getPurchasePayloads,
  isGA4,
  triggerBeginCheckoutEvent,
  triggerPurchaseEvents,
} from '@/checkout/helpers/ga4'
import CartWidget from '../components/cart/CartWidget.vue'
import CheckoutMobileFooter from '../components/cart/CheckoutMobileFooter.vue'
import MobileFooterPortal from '../components/cart/MobileFooterPortal.vue'
import { type PayButtonContext } from '@/helpers/CartHelpers'
import FormInput2 from '@/components/forms/FormInput2.vue'
import TixPayment from '@/checkout/components/TixPayment.vue'
import store from '../store/store'
import type { RawLocation } from 'vue-router'
import type { WithMeta } from '@/api/types/processedEntities'
import { setDocumentTitle } from '@/helpers/MiscellaneousHelpers'
import ScheduledPayments from '@/components/forms/ScheduledPayments.vue'
import type TixStripeError from '@/checkout/stripe/TixStripeError'
import type { CompletedOrdersResponse, PartialPaymentResponse } from '@/checkout/helpers/completing'
import CartAlreadyBookedError from '@/errors/CartAlreadyBookedError'
import NotFoundError from '@/errors/NotFoundError'

/**
 * Route-leave events are implemented in afterEach handlers.
 *
 * @see afterCheckoutRoute() in checkout-helpers/completing.ts
 *
 * Matrix of checkout scenarios & features and cart states;
 * @see https://docs.google.com/spreadsheets/d/1L3OMbW1Tc3CHpx3GgkIXvVRpzXamIEtdtqlUzHV4vYw/edit?usp=sharing
 *
 * TODO Use form="" attribute to regroup form inputs and "nest" all three forms on a single route;
 *    - Main checkout form
 *    - Gift cards
 *    - Promo codes
 *
 * @see https://stackoverflow.com/a/54901309
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-form
 *
 * Blocked by IE11 support:
 * @see https://caniuse.com/form-attribute
 */
@Component({
  name: 'CheckoutRoute',
  components: {
    ScheduledPayments,
    FormInput2,
    ApplyPromoCodesCheckout,
    Price,
    TixPayment,
    IdentityForm,
    BlockedCartForm,
    TermsCheckbox,
    CheckoutDonation,
    NavigateBack,
    CartWidget,
    MobileFooterPortal,
    CheckoutMobileFooter,
    FloatingNavigateBack,
    MembersBanner,
    VueHcaptcha,
  },
  computed: mapGetters({
    cart: 'Cart/cart',
    stripeAccountId: 'Cart/stripeAccountId',
    cartHasItems: 'Cart/cartHasItems',
    identityFormConfigurations: 'Cart/identityFormConfigurations',
    memberUser: 'Member/user',
    injectedCartOwner: 'Cart/injectedCartOwner',
    paymentDueByCreditCard: 'Cart/paymentDueByCreditCard',
    paymentDue: 'Cart/paymentDue',
  }),
})
export default class extends Vue {
  // Data properties
  loading = true
  // TODO Document the difference between processing and submitting
  // TODO Use :inert="processing" to prevent further user input after submit.
  // @see https://developer.chrome.com/articles/inert/
  // @see https://trello.com/c/otDSTvBV
  // @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
  processing = false
  submitting = false
  error: string | null = null
  validationError: string | null = null
  paymentError: string | null = null

  wallet: StripeWalletPayment | null = null
  supportedWallet: WalletType | null = null
  selectedPaymentType: PaymentMethodName | null = null

  // True if the cart starts free or ever became free since the route initialized.
  // This is used to determine if <CheckoutDonation> should appear above or below the identity form;
  //
  //   - It should be above for initially free carts, and paid carts that become free.
  //   - It should be below for paid carts.
  //   - It should move above for carts that become free, but not the other way;
  //   - It should stay above when a free cart becomes a paid cart. E.g. due to adding a donation on checkout.
  //
  // This promotes donations for free carts, while minimizing flashes of content and significant layout changes when
  // free carts become paid (and vice versa) with digital wallet payment methods.
  cartWasEverFree = false

  zipCodeDiscountRegExp: RegExp | false | null = null
  purchaserEmailAddress: string | null = null
  elements: StripeElements | null = null
  stripe: Stripe | null = null
  identityFormData: IdentityFormData = {}
  showMobileCart: boolean = false
  hasSentBeginCheckoutEvent = false

  // VueX store properties and methods.
  cart: Cart
  stripeAccountId: string
  cartHasItems: boolean
  identityFormConfigurations: string[]
  memberUser: UserEntity
  paymentDue: number
  paymentDueByCreditCard: number

  /**
   * @deprecated Identity does not indicate the cart is injected and it is not necessarily the owner.
   */
  injectedCartOwner: Identity

  hcaptchaToken: string | null = null

  partialPaymentResponse: PartialPaymentResponse | null = null

  // Options plugin properties.
  t: LanguageStrings['checkoutRoute']
  opt: ComponentOptions['checkoutRoute']

  @Watch('loading')
  handleLoadingChange(loading) {
    if (!loading) {
      this.$nextTick(() => {
        const mainEl = this.$refs.main as HTMLDivElement
        mainEl.addEventListener('focusin', this.handleInputFocus)
      })
    }
  }

  beforeRouteEnter(to, from, next) {
    setDocumentTitle('Checkout')

    if (memberHasNoEmailAddress()) {
      next('/user/set-email-address')
      return
    }

    const injecting = injectCart(to.params)
    if (injecting) {
      injecting
        .then(() => next())
        .catch((error) => {
          if (error.response?.status === 403) {
            // User is probably logged in. This scenario is handled in the template.
            next()
          } else if (error instanceof NotFoundError || error.response?.status === 404) {
            next(notFoundRoute(to))
          } else if (error instanceof CartAlreadyBookedError) {
            next(`/checkout/complete?orderIds=${error.orderId}`)
          } else {
            throw error
          }
        })
    } else if (!store.getters['Cart/cartHasItems']) {
      // No cart. Stop loading and return to home.
      next({ name: 'home' })
    } else {
      getPostCodeFilterRegularExpression().then((filter) => {
        if (filter) {
          next((vm) => {
            vm.zipCodeDiscountRegExp = filter
          })
        } else {
          next()
        }
      })
    }
  }

  @Watch('stripeInitializationKey', { immediate: true })
  initializePaymentMethod() {
    if (this.stripePaymentRequired) {
      loadStripe(environment.stripe.publishable_key, { stripeAccount: this.stripeAccountId }).then((stripe) => {
        if (stripe) {
          this.stripe = stripe
          if (this.$route.query.payment_intent_client_secret) {
            this.handleReturnFromPaymentRedirect()
          } else {
            this.initializeStripeWallet()
          }
        } else {
          this.error =
            'Credit card payment can not be processed because Stripe was unable to load. Try disabling any privacy blockers and ad blockers, and reloading the page.'
        }
      })
    } else {
      this.loading = false
      this.supportedWallet = 'none'
      this.stripe = null
    }
  }

  // Should attempt to initialize stripe whenever stripeAccountId and/or stripePaymentRequired change.
  get stripeInitializationKey(): string {
    return `${this.stripeAccountId}:${this.stripePaymentRequired}`
  }

  handleReturnFromPaymentRedirect() {
    const params = this.$route.query
    // TODO How to support AfterPay together with Captcha?
    const options = this.checkoutAPIOptions({}, params.purchaser)
    handlePaymentRedirectResponse(
      this.stripe!,
      params.cartId,
      params.payment_intent_client_secret,
      'afterpay_clearpay',
      options,
    )
      .then(this.onSuccess)
      .catch((e) => {
        // AfterPay failed. Let the customer try again, maybe with a different payment method.
        this.initializeStripeWallet()
        this.onError(e)
      })

    delete params.payment_intent_client_secret
    delete params.cartId
    delete params.purchaser
  }

  initializeStripeWallet() {
    const identityFields = getIdentityFormFields(this.identityFormData, this.identityFormConfigurations, [])
    this.wallet = new StripeWalletPayment(this.stripe!, this.paymentDueByCreditCard, identityFields)
    if (this.postCodeAsDiscountCodeEnabled) {
      // Do not enable wallet payments if using the "post code as discount" feature.
      // It is not compatible with wallet payments.
      this.supportedWallet = 'none'
    } else {
      this.wallet.enabled.then((result) => {
        // TODO TIC-1971 Get hCaptcha token immediately, at least for Apple Pay.
        this.supportedWallet = result
      })
    }
  }

  // `immediate` is necessary as the watcher for `initializePaymentMethod()` which sets `supportedWallet` also uses `immediate`.
  @Watch('supportedWallet', { immediate: true })
  observeSupportedWallet() {
    if (this.supportedWallet) {
      this.loading = false
      if (this.supportedWallet === 'none') {
        this.selectedPaymentType = 'card'
      } else {
        this.selectedPaymentType = this.supportedWallet === 'apple' ? 'apple_pay' : 'google_pay'
      }
    }
  }

  get orderId(): string | undefined {
    return this.$route.params.orderId
  }

  get isModifiable(): boolean {
    return store.getters['Cart/isModifiable']
  }

  get identityFormFields() {
    const omit = this.usesWallet ? this.wallet!.identityFieldNames : []
    return getIdentityFormFields(
      this.identityFormData,
      this.identityFormConfigurations,
      omit,
      this.selectedPaymentType,
    )
  }

  get stripePaymentRequired(): boolean {
    return this.paymentDueByCreditCard > 0
  }

  get usesWallet() {
    return this.stripePaymentRequired && this.isStripeWallet
  }

  get memberIdentity(): WithMeta<Identity> | void {
    return store.getters['Member/identityWithMeta'][0]
  }

  get giftee() {
    return store.getters['Cart/giftee']
  }

  get title() {
    if (this.giftee) {
      return `Pay for your Gift to ${this.giftee.name}`
    } else {
      return this.t.title
    }
  }

  get blocked(): boolean {
    return store.getters['Cart/blockingRules']?.length > 0
  }

  get memberMessage() {
    return this.$t('checkoutRoute.memberMessage', {
      email: `<em>${this.memberIdentity!.email}</em>`,
    }) as string
  }

  get changeMemberDetailsInstructions(): string {
    return changeMemberDetailsInstructions()
  }

  get showIdentityForm() {
    // Carts owned by the public identity must be re-assigned on checkout.
    return this.cart.ownedByPublicIdentity
  }

  get walletIdentityDataMessage(): string | false {
    if (this.usesWallet) {
      return this.$t('checkoutRoute.identityDataMessage', { wallet: walletNames[this.supportedWallet!] }) as string
    } else {
      return false
    }
  }

  get context(): PayButtonContext {
    return this.selectedPaymentType != null ? 'checkout' : 'default'
  }

  get showDonationForm(): boolean {
    return this.isModifiable && !this.giftee
  }

  get isPaymentLink() {
    return !this.isModifiable
  }

  @Watch('paymentDueByCreditCard', { immediate: true })
  updateCartWasEverFree() {
    // Compare to zero to allow credit from overpaid carts to be spent on donations.
    this.cartWasEverFree = this.cartWasEverFree || this.paymentDueByCreditCard === 0
  }

  onSubmit() {
    this.processing = true
    this.error = null
    this.paymentError = null

    this.validate()
      .then(this.processCheckoutOnApi)
      .then(this.onSuccess)
      .catch(this.onError)
      .catch((error) => {
        // Any other error.
        this.error = 'Sorry, something unexpected happened. Please try again later.'

        // Rethrow it so TrackJS logs it.
        throw error
      })
      .finally(() => {
        this.submitting = false
        this.processing = false
        this.showMobileCart = false
      })
  }

  private validate(): Promise<PaymentTokens> {
    return Promise.resolve()
      .then(this.validateIdentityAndTerms)
      .then(this.validateGiftee)
      .then(() => {
        // Promise.all is necessary to ensure these are run in parallel and must both have resolved before the next
        // step so that we don't attempt to show the hCaptcha challenge while one is already being shown.
        return Promise.all([this.confirmPaymentMethod(), this.validateCaptcha()]).then(([stripe]) => {
          // Don't pass on the captcha token as we get it again in the next step.
          return stripe
        })
      })
      .then((stripe) => {
        // Validate captcha again to handle the case where the captcha expires while user is on a wallet payment sheet.
        return this.validateCaptcha().then((captcha) => {
          return { stripe, captcha }
        })
      })
  }

  handleInputFocus() {
    if (!this.hasSentBeginCheckoutEvent) {
      this.hasSentBeginCheckoutEvent = true

      const tickets = store.getters['Cart/tickets'] as Ticket[]
      const templates = store.getters['Cart/eventTemplates'] as EventTemplate[]
      const types = store.getters['Cart/ticketTypes'] as TicketType[]
      const groups = store.getters['Cart/ticketGroups'] as TicketGroup[]
      const mods = store.getters['Cart/cartMods'] as CartMod[]

      const payload = getCartPayload(
        portal.default_currency_code,
        tickets.map((i) => i.id),
        tickets,
        templates,
        types,
        groups,
        mods,
      )
      triggerBeginCheckoutEvent(payload)
    }
  }

  onElements(elements: StripeElements | null) {
    this.elements = elements
  }

  private validateCaptcha(): Promise<string | undefined> {
    if (!this.captchaProvider || store.getters['Member/user']) {
      return Promise.resolve(undefined)
    } else if (this.hcaptchaToken) {
      return Promise.resolve(this.hcaptchaToken)
    } else {
      return this.getHcaptchaToken()
    }
  }

  private getHcaptchaToken(): Promise<string> {
    const el = this.$refs.hcaptcha as VueHcaptcha
    return el
      .executeAsync()
      .then(({ response: token }) => {
        this.hcaptchaToken = token
        return this.hcaptchaToken
      })
      .catch((error) => {
        // challenge-closed; User closed the challenge.
        // challenge-expired; User took too long to solve the challenge.
        if (error == 'challenge-closed' || error == 'challenge-expired') {
          throw new ValidationError()
        } else {
          throw error
        }
      })
  }

  // TODO Instead of using refs to validate child components, use form="" attribute
  // on form inputs to validate all form elements on native form-submit event.
  private validateIdentityAndTerms(): Promise<void> {
    const identityForm = this.$refs.identityForm as HTMLFormElement

    // Validate the identity form.
    return reportFormValidity(identityForm).then(() => {
      if (this.showTermsCheckbox) {
        // Validate the terms and conditions.
        const termsCheckbox = this.$refs.terms as TermsCheckbox
        return termsCheckbox.validate()
      } else {
        return Promise.resolve()
      }
    })
  }

  private validateGiftee(): Promise<void> {
    if (this.giftee && this.giftee.email === this.identityFormEmailAddress) {
      this.error =
        'You cannot gift a membership to yourself. Do you want to <membership-link>buy or renew a membership</membership-link>?'
      return Promise.reject(new ValidationError())
    } else {
      return Promise.resolve()
    }
  }

  private confirmPaymentMethod(): Promise<string | undefined> {
    this.submitting = true
    if (this.stripePaymentRequired) {
      return this.getStripePaymentMethod().then((paymentMethod) => {
        return paymentMethod.id
      })
    } else {
      // No payment required. E.g. Free carts
      return Promise.resolve(undefined)
    }
  }

  private getStripePaymentMethod(): Promise<PaymentMethod> {
    if (this.isStripeWallet) {
      return this.wallet!.openPaymentDialog(this.paymentDueByCreditCard!)
    } else {
      const payment = this.$refs.payment as Vue
      const identityFields = identityDataForStripe(this.identityFormData)
      return authorizePayment(this.stripe!, this.elements!, identityFields, payment.$el)
    }
  }

  get isStripeWallet() {
    return isWalletPayment(this.selectedPaymentType!)
  }

  private processCheckoutOnApi(tokens: PaymentTokens): Promise<CheckoutResult> {
    // Captcha Token is only valid for 1 use so have to clear the token after the API consumes it.
    this.hcaptchaToken = null

    // Store the purchaser's email address on the component instance now to be passed on to complete page later.
    // @see getCheckoutCompleteLocation()
    this.purchaserEmailAddress = this.identityFormEmailAddress

    return this.storePurchaser().then((purchaserId) => this.processPayments(tokens, purchaserId))
  }

  private storePurchaser(): Promise<string | undefined> {
    if (!this.showIdentityForm) {
      return Promise.resolve(undefined)
    } else {
      // Wallet identity values take precedence over form identity values.
      return upsertGuestAndGetId({
        ...this.identityFormData,
        ...this.wallet?.identityFormData,
      })
    }
  }

  private processPayments(tokens: PaymentTokens, purchaserId?: string): Promise<CheckoutResult> {
    const giftCards = store.getters['Cart/pendingGiftCardPayments']
    const creditCard = store.getters['Cart/paymentDueByCreditCard']
    const saveAndRenew = store.getters['Cart/autoRenewMembership']

    const payments = getPaymentPayloads(giftCards, creditCard, saveAndRenew, tokens, this.selectedPaymentType!)

    const options = this.checkoutAPIOptions(tokens, purchaserId)
    const shippingData = getRedirectPaymentData(this.cart.id, this.identityFormData, purchaserId)
    // Wallet payments use PaymentRequestButton rather than PaymentElement.
    const paymentElement = isWalletPayment(this.selectedPaymentType!) ? undefined : this.elements!
    return processPaymentPayloads(this.cart, this.stripe!, payments, options, paymentElement, shippingData)
  }

  checkoutAPIOptions(tokens: PaymentTokens, purchaserId?: string): CheckoutOptions {
    return {
      tokens,
      // TODO Better name for "injected" carts?
      injected: !this.isModifiable,
      suppressEmailNotification: this.orderId != null,
      purchaser: purchaserId,
      giftee: store.getters['Cart/giftee']?.id,
    }
  }

  private onSuccess(response: CheckoutResult) {
    // TODO Move GA handling out of here since it's getting long.
    // Push to GTM as soon as we have the order data.
    if (isGA4()) {
      if (response.status === 'completed') {
        const purchasePayloads = getPurchasePayloads(
          portal.default_currency_code,
          response.orders,
          store.getters['Cart/eventTemplates'] as EventTemplate[],
          store.getters['Cart/ticketTypes'] as TicketType[],
          store.getters['Cart/cartMods'] as CartMod[],
        )
        triggerPurchaseEvents(purchasePayloads)
      } else if (response.status === 'pending') {
        const purchasePayloads = getPendingPurchasePayloads(
          portal.default_currency_code,
          response.cart.cart_number,
          store.getters['Cart/tickets'] as Ticket[],
          store.getters['Cart/ticketGroups'] as TicketGroup[],
          store.getters['Cart/eventTemplates'] as EventTemplate[],
          store.getters['Cart/ticketTypes'] as TicketType[],
          store.getters['Cart/cartMods'] as CartMod[],
        )
        triggerPurchaseEvents(purchasePayloads)
      }
    }

    // The cart and other Vue X stores are cleared in a global afterEach() handler.
    // @see afterCheckoutRoute()

    clearTimer()

    if (response.status === 'partial') {
      this.partialPaymentResponse = response.cart
      store.dispatch('Cart/injectResponse', response.cart)
      this.elements?.getElement('payment')?.clear()
    } else {
      this.$router.push(this.getNextRoute(response))
    }

    if (this.orderId) {
      API.post(`ticket_order/${this.orderId}/email`, {
        body: { log_context: 'order_update' },
      })
    }
  }

  onError(error) {
    if (error.captchaError) {
      if (error.message === 'dismissed') {
        // User clicked outside the captcha challenge.
        logInTrackJS('Captcha Challenge Dismissed', error)
        return Promise.resolve()
      } else {
        throw error
      }
    } else if (error.validationError) {
      // Only shown to screen-readers, inform users there were validation errors
      // Clear existing validation errors first, aria-live only reads errors when they are added to the page
      this.validationError = null
      this.validationError =
        'There were errors found in the information you submitted. Please address the errors and re-submit the form.'
      return Promise.resolve()
    } else if (error.stripeError) {
      // Error occurred when attempting to pay e.g. card declined, cancelled Stripe's 3DS/2FA confirmation dialog.
      // See https://docs.stripe.com/error-codes for a list of all possible errors.

      // This is necessary if some payments succeeded, and others failed. E.g. gift cards succeeded but credit
      // card failed.
      this.refreshCart()

      this.logUnexpectedStripeError(error)
      this.paymentError = this.stripePaymentErrorMessage(error)
    } else {
      // Handle errors from tix-api.
      const apiError = getApiErrorEntity(error)
      if (apiError?._code === 'guest_account') {
        API.post('password_reset/request', { body: { email: this.identityFormEmailAddress } }).then(() => {
          this.error =
            'Oops! You have not set a password yet. Please check your email inbox for instructions to set a password, then try again.'
        })
      } else if (apiError?._code === 'incorrect_password') {
        this.error =
          'That is not the correct password. Please try again. <router-link to="/user/forgot-password">Forgot your password?</router-link>'
      } else {
        if (this.isStripeWallet) {
          // Cart can expire while user is completing wallet payment.
          this.error = apiErrorMessageOrRethrow(error, 'validate_not_email,cart_expired')
        } else {
          this.error = apiErrorMessageOrRethrow(error, 'validate_not_email')
        }
      }
    }
  }

  refreshCart() {
    if (this.isModifiable) {
      refreshStoredCart()
    } else {
      injectCart(this.$route.params)
    }
  }

  logUnexpectedStripeError(error: TixStripeError) {
    if (!this.isExpectedStripeError(error)) {
      const { type, code, param } = error.originalStripeError
      const message = `Stripe Error: ${type} - ${code ?? param}`
      logInTrackJS(message, error.originalStripeError)
    }
  }

  isExpectedStripeError(error: TixStripeError) {
    const expectedErrors = [
      'card_error:card_declined',
      'card_error:incorrect_zip',
      'card_error:incorrect_cvc',
      'card_error:invalid_cvc',
      // 3DS error
      'invalid_request_error:payment_intent_authentication_failure',
      // Bacs debit errors
      'invalid_request_error:invalid_bank_account',
      'invalid_request_error:invalid_bank_account_account_number',
    ]
    const { type, code } = error.originalStripeError
    return expectedErrors.includes(`${type}:${code}`)
  }

  stripePaymentErrorMessage(error: TixStripeError): string {
    const parts: string[] = []

    if (this.selectedPaymentType === 'card' || this.isStripeWallet) {
      parts.push('Your card has not been charged.')
    }

    const { type, message } = error.originalStripeError
    if (message && (type === 'card_error' || type === 'invalid_request_error')) {
      parts.push(message)
    } else {
      parts.push(fallbackErrorMessage)
    }

    if (this.isStripePostCodeError(error) && this.isStripeWallet) {
      parts.push(this.walletZipCodeErrorMessage())
    }

    return parts.join(' ')
  }

  isStripePostCodeError(error: TixStripeError): boolean {
    const code = error.originalStripeError.code
    return code === 'incorrect_zip' || code === 'postal_code_invalid'
  }

  walletZipCodeErrorMessage(): string {
    const label = configIdentityFields().zip_code.title ?? 'ZIP Code'
    const zip = this.wallet?.identityFormData.zip_code
    const wallet = this.selectedPaymentType === 'apple_pay' ? 'Apple Pay' : 'Google Pay'
    return `${label} is ${zip} in your ${wallet} address.`
  }

  get identityFormEmailAddress(): string {
    return this.identityFormData.email as string
  }

  getNextRoute(response: CompletedCheckoutResult | PendingCheckoutResult): RawLocation {
    if (this.orderId) {
      return `/manage/${this.orderId}`
    } else if (response.status === 'pending') {
      return {
        name: 'checkout/pending',
        params: {
          cartId: response.cart.id,
        },
      }
    } else {
      return {
        name: 'checkout/complete',
        query: {
          orderIds: response.orders.ticket_order._data.map((order) => order.id) as any,
        },
        params: {
          // @ts-expect-error Vue Router's types only document string values for entries in params and query.
          // TODO Find a better way to pass this data along or provide better type-safety.
          // @see https://router.vuejs.org/guide/essentials/navigation.html
          checkoutResponse: response.orders as CompletedOrdersResponse,
          // TODO Remove this route parameter.
          // @see https://tixtrackteam.slack.com/archives/CP9C2ET5E/p1629076975036500
          purchaserEmailAddress: this.purchaserEmailAddress ?? (this.memberIdentity?.email as any),
        },
      }
    }
  }

  // Redirect to the homepage if the cart becomes empty. This occurs when items are removed or the cart expires.
  // TODO Tell the customer why they were redirected to the homepage?
  @Watch('cartHasItems')
  redirectHomeOnEmptyCart(hasItems) {
    if (!hasItems) {
      this.$router.push({ path: '/' })
    }
  }

  // Apply member's post code as discount code code either on load, or on login.
  @Watch('memberIdentity', { immediate: true })
  userChanged() {
    if (this.memberIdentity) {
      const fields = this.memberIdentity.meta
      const zipCode = fields.zip_code
      if (zipCode) {
        this.applyDiscountCodeFromZipCode(zipCode)
      }
    }
  }

  applyDiscountCodeFromZipCode(zipCode: string): void {
    if (this.zipCodeDiscountRegExp) {
      // Get the part of the post code that should be used as a discount code.
      // Customers may enter a full zip code like 60607-1234.
      const matches = zipCode.match(this.zipCodeDiscountRegExp)

      if (matches?.length) {
        // Require benefit so that any existing exclusive promo codes will persist if this one does not yield a benefit.
        applyPromoCode(matches[0])
          // Ignore errors.
          .catch(() => null)
      }
    }
  }

  get emailToMessage(): string {
    return this.$t('checkoutRoute.ticketsEmailToMessage', {
      email: escapeHTML(this.injectedCartOwner.email),
    }) as string
  }

  get showApplyPromoCodes() {
    return (
      this.isModifiable &&
      environment.web.show_promo_codes_on_checkout &&
      store.getters['Cart/paymentDueByCreditCard'] > 0
    )
  }

  get hcaptchaPublicKey(): string | undefined {
    return environment.captcha?.hcaptcha_public
  }

  // Captcha isn't required for logged in members.
  // Show the message anyway to make it harder for bad actors to realise that logging in bypasses captcha.
  get captchaProvider(): 'hcaptcha' | undefined {
    return this.hcaptchaPublicKey !== undefined ? 'hcaptcha' : undefined
  }

  get hCaptchaEnabled(): boolean {
    return this.captchaProvider === 'hcaptcha'
  }

  get titleInsideContentColumn(): boolean {
    return configYml.titleInsideContentColumn ?? false
  }

  get makingScheduledPayments(): boolean {
    return (
      store.getters['Cart/hasScheduledPayments'] ||
      (store.getters['Cart/completedPayments'].length > 0 && this.paymentDueByCreditCard > 0)
    )
  }

  get showTermsCheckbox(): boolean {
    const content = environment.portalStrings.terms_and_conditions_dialog_content?.trim()
    return Boolean(content) && content.length > 0
  }

  get postCodeAsDiscountCodeEnabled() {
    return configYml.postCodeAsDiscountCode !== undefined
  }
}
