import type { ApplyCodePayload, ReserveTicketPayload } from '@/api/types/payloads'
import type { LinkedTG } from '@/api/types/processedEntities'
import { i18n } from '@/app/I18n'

export interface CartChanges {
  create?: true
  campaign?: Dictionary
  remove?: string[]
  // Only used for a preloaded promo code; A code set via URL, cookie or portal config.
  preloadedPromoCodes?: string[]
  // Used for;
  //   1. Unblocking reserve for events that require a code to add to cart
  //   2. CityPASS
  preReservePromoCodes?: ApplyCodeInModify
  add?: ReserveTicketPayload[]
  additionalInfo?: AdditionalTicketInfoPayload[]
  // Only used for extended membership.
  postReservePromoCodes?: ApplyCodeInModify
}

// Properties are specified in the same order that the API endpoint executes them, for convenience.
export interface ModifyCartPayload {
  create?: {
    channel_id: 'web'
    campaign?: Dictionary
  }
  remove?: {
    tickets: string[]
  }
  apply_code_default?: ApplyCodeInModify
  apply_code_before?: ApplyCodeInModify
  reserve?: {
    tickets: ReserveTicketPayload[]
    additional_info?: AdditionalTicketInfoPayload[]
  }
  apply_code?: ApplyCodeInModify
}

export interface AdditionalTicketInfoPayload {
  ticket_group_id: string
  data: Dict<Primitive>
}

export interface ApplyCodeInModify extends ApplyCodePayload {
  // By default, the modify API (in tix-web) ignores all subsequent requests after any request fails.
  ignore_errors?: boolean
}

export function reserveTicketsPayload(
  quantities: TicketTypeQuantities,
  session?,
  details: Dict<EventAdmitDetails[]> = {},
  data: Dict<ReserveTicketPayload['handler_data'][]> = {},
): ReserveTicketPayload[] {
  const tickets: ReserveTicketPayload[] = []

  for (const [id, { quantity, price }] of Object.entries(quantities)) {
    for (let i = 0; i < quantity; i++) {
      tickets.push({
        ticket_type_id: id,
        face_value: price,
        // `undefined` not `null`, so that the property is completely omitted from the JSON payload.
        event_session_id: session ? session.id : undefined,
        admit_name: details[id] ? details[id][i].name : undefined,
        admit_email: details[id] ? details[id][i].email : undefined,
        handler_data: data[id] ? data[id][i] : undefined,
      })
    }
  }

  return tickets
}

// TODO Consider support min and max values other than 1 e.g.
//   if (groups.length !== 1) return false
//   const g = groups[0]
//   return g.types.length === 1 && g.min_tickets_per_order === g.max_tickets_per_order
// Will also need to make changes where this is used to set quantity to 1.
export function exactlyOneTicket(groups: LinkedTG[]): boolean {
  return (
    groups.length === 1 &&
    groups[0].types.length === 1 &&
    groups[0].min_tickets_per_order === 1 &&
    groups[0].max_tickets_per_order === 1
  )
}

export function getButtonLabel(
  flexibleTickets: boolean,
  giftOfMembership: boolean,
  totalSelectedTickets: number,
): string {
  if (flexibleTickets) {
    if (giftOfMembership) {
      return i18n.tc(`reserve.buttonLabel.member`)
    } else {
      return i18n.tc(`reserve.buttonLabel.passes`, totalSelectedTickets)
    }
  } else {
    return i18n.tc(`reserve.buttonLabel.tickets`, totalSelectedTickets)
  }
}
