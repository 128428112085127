
import { API } from '@/api/API'
import type { CartApiResponse } from '@/api/Cart'
import { CalendarApiResponse, fetchCalendarForTicketTypes } from '@/api/calendar'
import { apiEntities, linkTGRelations, mergeConfigIntoEvent } from '@/api/Helpers'
import { fetchSessionsAndPrices } from '@/api/Sessions'
import type { ReserveTicketPayload } from '@/api/types/payloads'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import SelectDate from '@/components/events/SelectDate.vue'
import SelectSession from '@/components/events/SelectSession.vue'
import { apiErrorMessageOrRethrow, getApiErrorEntity, notFoundRoute } from '@/errors/helpers'
import { fetchOrderAndCart } from '@/helpers/CartHelpers'
import { formatCurrency } from '@/helpers/Currency'
import { groupBy, indexItemsById, itemIDs } from '@/helpers/IndexHelpers'
import { completeSentence } from '@/helpers/StringHelpers'
import { getTicketsFromCartForOrder } from '@/helpers/TicketEntity'
import { useFlexiblePrice } from '@/helpers/TicketType'
import { ensureLoggedOut, isLoggedIn } from '@/state/Authentication'
import { modify } from '@/state/Reserve'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type { Route } from 'vue-router'
import { isPastEditHorizon } from '@/helpers/SelfEdit'
import { TixTime } from '@/TixTime/TixTime'
import type { Session } from '@/types/Sessions'
import type { EventDetails } from '@/api/types/processedEntities'
import { sessionsWithPriceIncrease, ShowPricesConfig } from '@/helpers/PriceDisplay'
import { sum } from '@/helpers/Math'

@Component({
  name: 'RescheduleRoute',
  components: { SelectSession, SelectDate, NavigateBack },
})
export default class extends Vue {
  @Prop({ required: true })
  sessionId: string

  @Prop({ required: true })
  orderId: string

  selectedDate: null | TixTime = null
  selectedSession: null | Session = null
  dates: null | CalendarApiResponse = null
  sessions: null | Session[] = null
  submitting: boolean = false

  cart: CartApiResponse | null = null

  errorMessage: string | null = null
  initErrorMessage: string | null = null
  warningMessage: string | null = null

  expectedApiErrors = [
    'event_session_sold_out:reserve',
    'tickets_too_old:cart_remove_tickets',
    'ticket_state_incorrect:cart_remove_tickets',
  ]

  beforeRouteEnter(to: Route, from, next) {
    ensureLoggedOut()
      .then(() => fetchOrderAndCart(to.params.orderId))
      .then((response) => next((vm) => (vm.cart = response.cart)))
      .catch((error) => {
        if (error?.response?.status === 404) {
          next(notFoundRoute(to))
        } else {
          throw error
        }
      })
  }

  @Watch('selectedDate')
  fetchSessions() {
    this.selectedSession = null
    this.sessions = null

    if (this.selectedDate) {
      // The price schedule for flexible price ticket types is irrelevant.
      // TODO Fetch price schedule for fixed-price ticket-types only.
      // E.g. Where :idList is the comma-separated list of IDs of fixed-price ticket types:
      // GET /v1/ticket_type?id._in=:idList&_price_at._range=2022-09-01T18:00:00Z,2022-09-02T18:00:00Z
      fetchSessionsAndPrices(this.eventDetails!, this.selectedDate!, this.pricingQuantities).then(
        ({ sessions, priceSchedules }) => {
          this.sessions = sessionsWithPriceIncrease(sessions, priceSchedules, this.currentScheduledPrice)
        },
      )
    }
  }

  @Watch('eventDetails', { immediate: true })
  fetchCalendarDates() {
    if (this.eventDetails) {
      fetchCalendarForTicketTypes(this.eventDetails.id, this.pricingQuantities, this.showPrices.dates).then(
        (response) => {
          this.dates = response
          this.selectedDate = null
        },
      )
    }
  }

  @Watch('selectedSession')
  onSelectedSessionChange(selectedSession: Session) {
    if (selectedSession?.priceIncrease && selectedSession.priceIncrease > 0) {
      const time = selectedSession.startTime.format('LT')
      const cost = formatCurrency(selectedSession.priceIncrease)
      this.warningMessage = this.$t('rescheduleRoute.costIncreaseWarning', { time, cost }) as string
    } else {
      this.warningMessage = ''
    }
  }

  get editableTickets(): StandardTicket[] {
    return (getTicketsFromCartForOrder(this.cart!, this.orderId) as StandardTicket[])
      .filter((ticket) => !isPastEditHorizon(ticket.start_datetime!, ticket.timezone))
      .filter((ticket) => ticket.event_session_id === this.sessionId)
  }

  get session(): EventSession {
    return this.cart!.event_session._data.find(({ id }) => id === this.sessionId)!
  }

  // TODO Move error handling to initialization.
  // Can eventDetails by a data property that is only set at initialization time?
  get eventDetails(): EventDetails | null {
    this.initErrorMessage = null
    this.errorMessage = null

    if (this.cart && !this.isLoggedIn) {
      if (!this.session || this.editableTickets.length < 1) {
        // TODO Getters should not have side effects. Handle this when this.cart is set. Where is it set!?
        this.$notFound()
      } else {
        const entities = apiEntities(this.cart)
        const template = entities.event_template.find((template) => template.id === this.session.event_template_id)!
        const venue = entities.venue.find((venue) => venue.id === template.venue_id)!
        const types = this.filteredTicketTypes

        return {
          ...mergeConfigIntoEvent(template, this.cart),
          venue,
          ticketTypes: types,
          ticketGroups: linkTGRelations(this.filteredTicketGroups, entities.meta, types),
        }
      }
    }

    this.initErrorMessage = 'Oops! Sorry, something went wrong. Please try again.'
    return null
  }

  /**
   * Only include the Ticket Types relevant for tickets booked for this session.
   *
   * TODO De-duplicate or rename get ticketTypes()
   */
  get filteredTicketTypes(): TicketType[] {
    const types = groupBy('ticket_type_id', this.editableTickets)
    return this.cart!.ticket_type._data.filter((type) => types[type.id])
  }

  /**
   * Only include the Ticket Groups relevant for tickets booked for this session.
   */
  get filteredTicketGroups(): TicketGroup[] {
    const groups = groupBy('ticket_group_id', this.editableTickets)
    return this.cart!.ticket_group._data.filter((group) => groups[group.id])
  }

  get name() {
    return this.eventDetails!.name
  }

  get ticketCount() {
    return this.$tc('reserve.ticketQuantity', this.editableTickets.length)
  }

  get dateTime(): TixTime {
    return new TixTime(this.session.start_datetime, this.eventDetails!.venue.timezone)
  }

  get cartId(): string {
    return this.cart!.cart._data[0].id
  }

  get currentScheduledPrice(): number {
    const types = this.ticketTypes
    const values = this.editableTickets.map((ticket) => {
      const type = types[ticket.ticket_type_id]
      // Use the ticket type value to calculate paid amount for flexible-price tickets
      // to match what the API does for per-session price schedules.
      // TODO Ignore flexible tickets instead.
      // @see https://trello.com/c/aCinW8oH/1538-edits-%F0%9F%90%9E-flex-price-tickets-are-repriced#comment-6125f1f5b4058052a359d697
      const value = useFlexiblePrice(type) ? type.currency_amount : ticket.face_value
      return Number(value)
    })
    return sum(values)
  }

  get pricingQuantities(): TicketTypeQuantities {
    const result: TicketTypeQuantities = {}
    this.editableTickets.forEach((ticket) => {
      result[ticket.ticket_type_id] ??= { quantity: 0 }
      result[ticket.ticket_type_id].quantity += 1
    })
    return result
  }

  get ticketTypes() {
    return indexItemsById(this.eventDetails!.ticketTypes)
  }

  get reservePayload(): ReserveTicketPayload[] {
    const types = this.ticketTypes

    return this.editableTickets.map((ticket) => ({
      event_session_id: this.selectedSession!.id,
      ticket_type_id: ticket.ticket_type_id,

      // Carry over on any custom name or email address verbatim.
      admit_name: ticket.admit_name,
      admit_email: ticket.admit_email,

      // Carry over the value for flexible-price tickets. Let the API calculate the value for fixed-price tickets,
      // taking price overrides into account. Neither the CMS nor tix-web-client support price overrides for flexible
      // price ticket types, even though the API and data model support it. No customer has requested support for it.
      face_value: useFlexiblePrice(types[ticket.ticket_type_id]) ? ticket.face_value : undefined,
    }))
  }

  get isLoggedIn(): boolean {
    return isLoggedIn()
  }

  get rescheduleDescription() {
    return this.$t('rescheduleRoute.description', {
      name: this.name,
      count: this.ticketCount,
      date: this.dateTime.format('LONGER_DATE'),
      time: this.dateTime.format('LT'),
    })
  }

  get showPrices(): ShowPricesConfig {
    return { dates: false, sessions: false }
    // TODO Enable price display on reschedule and fix "$0.00" instead of "FREE".
    // return showPrices(Object.values(this.ticketTypes))
  }

  onSubmit() {
    this.submitting = true

    modify(`cart/${this.cartId}/modify`, {
      remove: itemIDs(this.editableTickets),
      add: this.reservePayload,
    })
      .then(() => {
        this.$router.push({ path: `/manage/${this.orderId}` })
      })
      .catch((e) => {
        const expected = this.expectedApiErrors.join(',')
        this.errorMessage = apiErrorMessageOrRethrow(e, expected)

        const apiError = getApiErrorEntity(e)
        if (this.shouldRedirect(apiError)) {
          this.initErrorMessage = `${completeSentence(apiError!._description)} <a href="manage/${
            this.orderId
          }">Click here to start again.</a>`
        } else {
          // Abandon changes on error
          // Don't catch and set errorMessage otherwise it will override what has been set above.
          API.delete(`cart/${this.cartId}`).catch((e) => {
            // If neither the remove/reserve requests were successful, there is no cart to delete
            // An expected error, incorrect_cart_state error will be returned in this case
            apiErrorMessageOrRethrow(e, 'incorrect_cart_state')
          })
        }
        this.selectedDate = null
      })
      .finally(() => {
        this.submitting = false
      })
  }

  shouldRedirect(error: ApiErrorEntity | void) {
    if (!error) {
      return false
    }

    if (error._context === 'reserve' && error._code === 'cart_expired') {
      return true
    }

    const matchesCode = ['tickets_too_old', 'ticket_state_incorrect', 'ticket_not_found'].includes(error._code)
    if (error._context === 'cart_remove_tickets' && matchesCode) {
      return true
    }

    return false
  }

  get sessionPickerTabsConfig() {
    return this.eventDetails?.config.web?.session_picker_tabs
  }
}
