
import type { ReserveTicketPayload } from '@/api/types/payloads'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import Survey2 from '@/components/forms/Survey2.vue'
import TicketTypeRadioItem from '@/components/forms/TicketTypeRadioItem.vue'
import { surveySpecsWithTicketGroupId, SurveySpecWithTicketGroupID } from '@/helpers/SurveyHelpers'
import { reportFormValidity } from '@/helpers/Validation'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MobileFooterPortal from '../cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '../cart/ReserveMobileFooter.vue'
import EventDetailErrorMessages from '@/components/events/EventDetailErrorMessages.vue'
import { reserveSubmitHandler, ReserveSubmitOptions } from '@/state/Reserve'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'

interface SelectedType {
  id: string
  amount?: string
}

/**
 * A selector for special events where items are reserved one at a time.
 *
 * E.g. Donations, gift cards, memberships.
 *
 * This is an alternative to <ReserveDateFirst> and <ReserveQuantityFirst>.
 */
@Component({
  name: 'AddOneToCart',
  components: {
    TicketTypeRadioItem,
    NavigateBack,
    Survey2,
    MobileFooterPortal,
    ReserveMobileFooter,
    EventDetailErrorMessages,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  category: string

  @Prop()
  contexts: Set<string>

  selectedType: SelectedType | null = null

  apiError: any = null
  submitting = false

  surveyAnswers: Dict<Dict<Primitive>> = {}

  readonly formId = 'add-one-to-cart-form'

  get buttonLabel() {
    const language: Dictionary = this.$t('reserve.buttonLabel') as any
    return language[this.category] || language.default
  }

  mounted() {
    const select = this.$route.query.select
    if (select) {
      this.selectedType = {
        id: select,
      }
    } else if (this.event.ticketGroups.length === 1 && this.event.ticketGroups[0].types.length === 1) {
      this.selectedType = {
        id: this.event.ticketGroups[0].types[0].id,
      }
    }
  }

  onSubmit() {
    this.submit()
  }

  submit(codes?: string[]): Promise<void> {
    const submitOptions: ReserveSubmitOptions = {
      ticketsPayload: [this.reservation],
      surveyAnswers: this.surveyAnswers,
      selectedGroups: this.selectedGroups,
      emitCallback: this.$emit.bind(this),
      codes: codes,
    }

    return this.validate()
      .then(() => {
        this.submitting = true
      })
      .then(() => reserveSubmitHandler(submitOptions))
      .catch((error) => {
        this.apiError = error
      })
      .finally(() => {
        this.submitting = false
      })
  }

  validate() {
    return reportFormValidity(this.$refs.form as HTMLFormElement)
  }

  get reservation(): ReserveTicketPayload {
    const type = this.selectedType as SelectedType
    return {
      ticket_type_id: type.id,

      // Use `undefined` so the property is omitted when serializing to JSON.
      face_value: type.amount || undefined,

      // TODO Document this oddity better.
      admit_name: '',
      admit_email: '',
    }
  }

  get selectedGroups(): LinkedTG[] {
    return this.event.ticketGroups.filter((group) => {
      return group.types.some((type) => this.selectedType?.id === type.id)
    })
  }

  get surveySpecs(): SurveySpecWithTicketGroupID[] {
    return surveySpecsWithTicketGroupId(this.selectedGroups)
  }
}
