
import { CalendarApiResponse, fetchCalendarForTicketTypes } from '@/api/calendar'
import { fetchSessionsAndPrices, fetchSessionsAvailability } from '@/api/Sessions'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import RenewMembershipMessage from '@/components/elements/RenewMembershipMessage.vue'
import AdmitDetailsFields from '@/components/events/AdmitDetailsFields.vue'
import SelectDate from '@/components/events/SelectDate.vue'
import SelectQuantities from '@/components/events/SelectQuantities.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import Survey2 from '@/components/forms/Survey2.vue'
import { reserveLanguage } from '@/language/helpers'
import { surveySpecsWithTicketGroupId, SurveySpecWithTicketGroupID } from '@/helpers/SurveyHelpers'
import { sumQuantities } from '@/helpers/TicketTypeQuantities'
import { reportFormValidity } from '@/helpers/Validation'
import { reserveSubmitHandler, ReserveSubmitOptions } from '@/state/Reserve'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MobileFooterPortal from '../cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '../cart/ReserveMobileFooter.vue'
import DynamicMessageGroup from '@/components/events/DynamicMessageGroup.vue'
import MobileCartButton from '@/components/elements/MobileCartButton.vue'
import MobileTotal from '@/components/elements/MobileTotal.vue'
import { isChainedUpsell } from '@/helpers/EventHelpers'
import EventDetailErrorMessages from '@/components/events/EventDetailErrorMessages.vue'
import { TixTime } from '@/TixTime/TixTime'
import { reserveTicketsPayload } from '@/helpers/Reserve'
import SelectSession from '@/components/events/SelectSession.vue'
import { apiErrorMessageOrRethrow, getApiErrorEntity } from '@/errors/helpers'
import type { Session } from '@/types/Sessions'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { sessionsWithPrices, showPrices } from '@/helpers/PriceDisplay'
import AvailabilityStatus from '@/components/elements/AvailabilityStatus.vue'
import { processCalendarApiResponse } from '@/calendar/helpers'
import { capacityThreshold, showAvailability } from '@/helpers/AvailabilityHelpers'

/**
 * TODO Add support for admission passes & split this into smaller components.
 * @see https://tixtrack.atlassian.net/browse/TIC-1809
 */
@Component({
  name: 'ReserveQuantityFirst',
  components: {
    AvailabilityStatus,
    SelectSession,
    MobileTotal,
    MobileCartButton,
    AdmitDetailsFields,
    FormInput2,
    NavigateBack,
    SelectDate,
    SelectQuantities,
    Survey2,
    MobileFooterPortal,
    ReserveMobileFooter,
    RenewMembershipMessage,
    DynamicMessageGroup,
    EventDetailErrorMessages,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  @Prop()
  contexts: Set<string>

  readonly formId = 'reserve-quantity-first-form'

  quantitiesClosed: boolean = false
  selectedTicketTypes: TicketTypeQuantities = {}

  dates: CalendarApiResponse | null = null

  selectedDate: TixTime | null = null

  sessionClosed: boolean = false
  allSessions: Session[] | null = null
  selectedSession: Session | null = null

  surveyAnswers: Dict<Dict<Primitive>> = {}
  details: Dict<EventAdmitDetails[]> = {}

  submitting: boolean = false
  apiError: any = null
  fetchAvailabilityCalendarError: string | null = null
  sessionsAvailable = false
  loading = true

  l = reserveLanguage('reserveQuantityFirst')

  mounted() {
    fetchSessionsAvailability(this.event)
      .then((available) => {
        this.sessionsAvailable = available
      })
      .finally(() => {
        this.loading = false
      })
  }

  get quantitiesTotal() {
    return sumQuantities(this.selectedTicketTypes)
  }

  get totalAvailableDates(): number {
    const available = Object.values(this.dates!).filter((day) => day.status === 'available')
    return available.length
  }

  get availableSessions(): Session[] {
    return this.allSessions ? this.allSessions.filter((session) => !session.sold_out) : []
  }

  get selectedGroups(): LinkedTG[] {
    return this.event.ticketGroups.filter((group) => {
      return group.types.some((type) => this.selectedTicketTypes[type.id]?.quantity > 0)
    })
  }

  get surveySpecs(): SurveySpecWithTicketGroupID[] {
    return surveySpecsWithTicketGroupId(this.selectedGroups)
  }

  @Watch('selectedSession')
  onSessionChange(selectedSession: Session) {
    selectedSession ? this.closeSessions() : this.openSessions()
  }

  @Watch('selectedDate')
  onDateChange(selectedDate: Date) {
    selectedDate ? this.closeDate() : this.openDate()
  }

  @Watch('event')
  openQuantities() {
    this.quantitiesClosed = false
    this.resetSessions()
    this.resetDates()
  }

  closeQuantities() {
    this.quantitiesClosed = true
    this.apiError = null
    this.fetchAvailabilityCalendarError = null

    fetchCalendarForTicketTypes(this.event.id, this.selectedTicketTypesQuantities, this.showPrices.dates)
      .then((response) => {
        this.dates = response
      })
      .catch((err) => {
        this.quantitiesClosed = false
        const error = getApiErrorEntity(err)
        if (error?._code === 'validation_failed' && error?._context === 'event_calendar') {
          this.fetchAvailabilityCalendarError = 'You can only select up to 10 different types of tickets.'
        } else {
          this.fetchAvailabilityCalendarError = apiErrorMessageOrRethrow(err)
        }
      })
  }

  openDate() {
    // Deselect the date to expose the first 8 available dates.
    this.selectedDate = null
    this.resetSessions()
  }

  closeDate() {
    fetchSessionsAndPrices(this.event, this.selectedDate!, this.selectedTicketTypes).then((result) => {
      this.allSessions = sessionsWithPrices(result.sessions, result.priceSchedules)
    })
  }

  get showPrices() {
    const selectedTTs = this.event.ticketTypes.filter((type) => this.selectedTicketTypes[type.id]?.quantity > 0)
    return showPrices(selectedTTs)
  }

  openSessions() {
    this.sessionClosed = false
    this.selectedSession = null
  }

  closeSessions() {
    this.sessionClosed = true
  }

  resetSessions() {
    this.allSessions = null
    this.selectedSession = null
    this.sessionClosed = false
    this.apiError = null
  }

  resetDates() {
    this.dates = null
    this.selectedDate = null
    this.apiError = null
  }

  onSubmit() {
    this.submit()
  }

  submit(codes?: string[]): Promise<void> {
    const submitOptions: ReserveSubmitOptions = {
      ticketsPayload: reserveTicketsPayload(this.selectedTicketTypes, this.selectedSession, this.details, undefined),
      surveyAnswers: this.surveyAnswers,
      selectedGroups: this.selectedGroups,
      emitCallback: this.$emit.bind(this),
      codes: codes,
    }

    return this.validate()
      .then(() => {
        this.submitting = true
      })
      .then(() => reserveSubmitHandler(submitOptions))
      .catch((error) => {
        this.apiError = error
      })
      .finally(() => {
        this.submitting = false
      })
  }

  validate() {
    return reportFormValidity(this.$refs.form as HTMLFormElement)
  }

  get earliestDate(): TixTime {
    // Fall back on today (undefined).
    const firstDate = this.dates?.[0].date ?? undefined
    // TODO Use TixDate instead.
    return this.selectedDate ?? new TixTime(firstDate, this.event.venue.timezone)
  }

  get showCart() {
    return this.$store.getters['Cart/ticketCount'] > 0
  }

  get isChainedUpsell() {
    return isChainedUpsell(this.$route)
  }

  get selectedTicketTypesQuantities(): TicketTypeQuantities {
    const result: TicketTypeQuantities = {}
    for (const [ttId, quantityAndPrice] of Object.entries(this.selectedTicketTypes)) {
      if (quantityAndPrice.quantity > 0) {
        result[ttId] = quantityAndPrice
      }
    }
    return result
  }

  get sessionPickerTabsConfig() {
    return this.event.config.web?.session_picker_tabs
  }

  get dayData() {
    if (!this.dates) {
      return []
    }

    return processCalendarApiResponse(this.dates, this.event)
  }

  get capacityThreshold() {
    return capacityThreshold(this.dayData)
  }

  get selectedDateData() {
    return this.dayData?.find((d) => this.selectedDate?.isSame(d.date.format('DATE'), 'day'))
  }

  get showAvailability(): boolean {
    return showAvailability(this.capacityThreshold)
  }

  get selectQuantitiesTitle() {
    return this.$t('stepperGroupTitles.default', { type: 'tickets' })
  }

  get selectQuantitiesDescription() {
    return this.$t('stepperGroupDescriptions.default')
  }
}
