
import { fetchDonationTG, fetchEventDetails } from '@/api/Events'
import RadioGroup from '@/components/forms/RadioGroup.vue'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import { environment } from '@/helpers/Environment'
import { CartChanges, reserveTicketsPayload } from '@/helpers/Reserve'
import { replaceTickets } from '@/state/Reserve'
import store from '@/store/store'
import { Component, Vue, Watch } from 'vue-property-decorator'
import type { LinkedTG } from '@/api/types/processedEntities'
import { sellerConfig } from '@/helpers/Config'

@Component({
  name: 'CheckoutDonation',
  components: { RadioGroup },
})
export default class extends Vue {
  ticketGroupId = sellerConfig('checkout_donation_tg_id')

  // TODO Remove this once all tenants have migrated to checkout_donation_tg_id.
  eventId = environment.web.checkout_upsell_id

  ticketGroup: LinkedTG | null = null
  selected: string | null = null
  errorMessage: string | null = null
  submitting = false

  created() {
    if (this.ticketGroupId) {
      fetchDonationTG(this.ticketGroupId)
        .then((group) => {
          this.ticketGroup = group
        })
        .catch((error) => {
          // Do not let misconfigured checkout donation TGs surface to the UI.
          apiErrorMessageOrRethrow(error)
        })
    } else if (this.eventId) {
      // TODO Remove this code path once checkout_upsell_id is no longer used.
      fetchEventDetails(this.eventId).then((event) => {
        const groups = event.ticketGroups.filter((group) => group.handler === 'donation')
        if (groups.length > 0) {
          this.ticketGroup = groups[0]
        }
      })
    }
  }

  /**
   * Restore selected donation on reload.
   */
  @Watch('ticketGroup')
  onTicketGroupChange() {
    if (this.ticket) {
      this.selected = this.ticket.ticket_type_id
    }
  }

  get options(): RadioGroupOption[] {
    if (this.ticketGroup) {
      return this.ticketGroup.types.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    } else {
      return []
    }
  }

  onSelect() {
    this.submitting = true
    this.errorMessage = null

    replaceTickets(this.cartChanges)
      .catch((error) => {
        this.errorMessage = apiErrorMessageOrRethrow(error)
      })
      .finally(() => {
        this.submitting = false
      })
  }

  private get cartChanges(): CartChanges {
    return {
      remove: this.ticket ? [this.ticket.id] : [],
      add: this.selected ? reserveTicketsPayload({ [this.selected]: { quantity: 1 } }) : [],
    }
  }

  private get ticket(): Ticket | null {
    const tickets = store.getters['Cart/tickets']
    if (this.ticketGroupId) {
      return tickets.find((ticket) => ticket.ticket_group_id === this.ticketGroupId)
    } else if (this.eventId) {
      return tickets.find((ticket) => ticket.event_template_id === this.eventId)
    } else {
      return null
    }
  }
}
