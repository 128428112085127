import type { EventWithConfig, LinkedTG, MembershipConfig } from '@/api/types/processedEntities'
import { filterNamespacedProperties } from '@/helpers/DictHelpers'
import { configYml, environment } from '@/helpers/Environment'
import { setObjectPropertyByPath } from '@/helpers/MiscellaneousHelpers'
import language from '@/language'
import type { I18nOverrides } from '@/language/types'
import type { Env } from '@/types/Env'
import deepmerge from 'deepmerge'
import type { LocaleMessages } from 'vue-i18n'

type SellerConfig = Required<Env>['sellerConfig']
export function sellerConfig<Key extends keyof SellerConfig>(name: Key): SellerConfig[Key] {
  return environment.sellerConfig?.[name] ?? environment.web[name]
}

/**
 * @deprecated Replace <TenantContent> with portal strings.
 */
export function getCustomContentBlocks(config?: CustomContentBlock[]): Dict<CustomContentBlock[]> {
  const result: Dict<CustomContentBlock[]> = {}
  if (config) {
    // Get templates that have been overridden in the portal strings database table.
    const overrides = filterNamespacedProperties('block:', environment.portalStrings)
    for (const block of config) {
      block.regions.forEach((region) => {
        if (overrides[block.key]) {
          block.template = `<div>${overrides[block.key]}</div>`
        }
        result[region] = result[region] || []
        result[region].push(block)
      })
    }
  }
  return result
}

/**
 * Gets I18n strings including overrides from portal strings and seller metadata.
 */
export function languageStrings(): Dict<LocaleMessages> {
  const yaml = configYml.i18nOverrides || {}

  // TODO Add a language column to portal_string table.
  const portal = {
    en: parseFlatI18nOverrides(environment.portalStrings),
  }

  // TODO Move seller overrides to seller_string table or abstract portal_string to be more generic.
  const seller = parseFlatI18nOverrides(environment.sellerMeta ?? {})

  // Ordered overrides; Later items take precedence.
  const sources = [language, yaml, portal, seller]

  // Merge the overrides in order.
  return sources.reduce((a, b) => deepmerge(a, b)) as Dict<LocaleMessages>
}

function parseFlatI18nOverrides(data: Dictionary): I18nOverrides {
  const result: I18nOverrides = { en: {} }
  const overrides = filterNamespacedProperties('i18n:', data)

  Object.entries(overrides).forEach(([path, value]) => {
    setObjectPropertyByPath(result, path, value)
  })

  return result
}

/**
 * When doing `getMembershipConfig()?.prop` consider if you should be using `getMembershipConfigProperty()` instead.
 *
 * @example
 * TicketGroup
 *  {
 *    name: {
 *      prop2: 10
 *    }
 *  }
 * Event
 *  {
 *    name: {
 *      prop1: 5
 *    }
 *  }
 *
 * getMembershipConfig('name', ...)?.prop1 would return null
 */
export function getMembershipConfig<Name extends keyof MembershipConfig>(
  name: Name,
  ticketGroup: LinkedTG,
  event: EventWithConfig,
  legacy?: MembershipConfig[Name],
): MembershipConfig[Name] | null {
  const tgConfig = ticketGroup.config?.membership?.[name]
  const eventConfig = event.config?.membership?.[name]
  const envConfig = environment.membership?.[name]
  return tgConfig ?? eventConfig ?? envConfig ?? legacy ?? null
}

/**
 * Use this when a config may have multiple properties, but you only care about a specific one.
 *
 * @example
 * TicketGroup
 * {
 *   name: {
 *     prop2: 10
 *   }
 * }
 *
 * Event
 * {
 *   name: {
 *     prop1: 5
 *   }
 * }
 *
 * getMembershipConfigProperty('name', 'prop1', ...) would return 5
 */
export function getMembershipConfigProperty<
  Name extends keyof MembershipConfig,
  Prop extends keyof NonNullable<MembershipConfig[Name]>,
>(
  name: Name,
  property: Prop,
  ticketGroup: LinkedTG | undefined,
  event: EventWithConfig | undefined,
  legacy?: NonNullable<MembershipConfig[Name]>[Prop],
): NonNullable<MembershipConfig[Name]>[Prop] | null {
  const tgConfig = getNestedProperty(ticketGroup?.config?.membership?.[name], property)
  const eventConfig = getNestedProperty(event?.config?.membership?.[name], property)
  const envConfig = getNestedProperty(environment.membership?.[name], property)
  return tgConfig ?? eventConfig ?? envConfig ?? legacy ?? null
}

// Function to get nested property when the object may be undefined
function getNestedProperty<T, K extends keyof NonNullable<T>>(
  obj: T | undefined,
  key: K,
): NonNullable<T>[K] | undefined {
  return obj ? obj[key] : undefined
}
